import React, { useEffect, useRef } from 'react';
import onScroll from '#/common/scroller';

export default function ScrollIndicator() {
  const ref = useRef();
  useEffect(() => {
    const listener = () => {
      const docEl = document.documentElement;
      const scrollTop = document.body.scrollTop || docEl.scrollTop;
      const height = docEl.scrollHeight - docEl.clientHeight;
      const scrolled = scrollTop / height;
      ref.current.style.transform = `scaleX(${scrolled})`;
    };
    return onScroll(listener);
  });
  return (
    <div className="tw-h-px tw-absolute tw-left-0 tw-right-0 tw-bottom-px">
      <div ref={ref} className="tw-w-full tw-h-full tw-origin-left tw-bg-blue-300 tw-transform tw-scale-x-0" />
    </div>
  );
}
