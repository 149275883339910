import { Link, StaticQuery, graphql } from 'gatsby';
import React from 'react';
import ScrollIndicator from '../scroll-indicator';

function Header({ data, showIndicator }) {
  const {
    site: {
      siteMetadata: {
        categories,
        pages,
      },
    },
  } = data;
  return (
    <header>
      <nav>
        <Link to="/">Gerald</Link>
        {categories.filter(({ title }) => title).map(({ type, title }, i) => (
          <Link key={i} activeClassName="active" to={`/${type}/`} partiallyActive>{title}</Link>
        ))}
        <span className="tw-flex-1" />
        {pages.map(({ href, title }, i) => (
          <Link key={i} activeClassName="active" to={href} partiallyActive>{title}</Link>
        ))}
      </nav>
      {showIndicator && <ScrollIndicator />}
    </header>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            categories {
              type
              title
            }
            pages {
              href
              title
            }
          }
        }
      }
    `}
    render={data => (
      <Header {...props} data={data} />
    )}
  />
);
