import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import Header from '../header';
import Footer from '../footer';
import icon from '#/assets/avatar.jpg';
import '#/common/style.css';
import '#/common/prism.css';

function Layout(props) {
  const {
    data: {
      site: {
        siteMetadata: {
          title,
        },
      },
    },
    hideHeader,
    showIndicator,
    children,
  } = props;
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="shortcut icon" type="image/jpeg" href={icon} />
      </Helmet>
      {!hideHeader && <Header showIndicator={showIndicator} />}
      <div>
        {children}
        <Footer />
      </div>
    </>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Layout
        {...props}
        data={data}
      />
    )}
  />
);
